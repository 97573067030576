import { Checkbox, Input, Select, Switch, Tag, Tooltip } from "antd";
import CustomSelect from "components/shared-components/DefaultSearchSelect";

/**
 * Validates whether a given string contains only letters.
 *
 * This function checks if the provided value consists exclusively of alphabetical characters (A-Z, a-z).
 * It is designed to be used in scenarios where input validation is required, such as form inputs.
 *
 * @param {string} _ - A placeholder parameter, typically representing the field name in form validation contexts. Not used in the function.
 * @param {string} value - The string to be validated.
 *
 * @returns {Promise} - A Promise that resolves if the validation is successful (i.e., the string contains only letters),
 *                      or rejects with an Error stating "Only letters are allowed" if the validation fails.
 *
 * Usage example:
 * onlyLettersValidator('username', 'JohnDoe').then(() => {
 *   console.log('Validation passed');
 * }).catch((error) => {
 *   console.error(error.message);
 * });
 */
export const onlyLettersValidator = (_, value) => {
  // Using a ternary operator for concise conditional logic.
  // The test() method is sufficient for checking the pattern; no need to explicitly check for an empty value.
  return /^[A-Za-z]*$/.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error("Only letters are allowed"));
};

export const onlyNumbersValidator = (_, value) => {
  // Using a regular expression to check if the value contains only numbers
  // and is not an empty string.
  return /^[0-9]+$/.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error("Only non-negative numbers are allowed"));
};

export const numbersWithDecimalValidator = (_, value) => {
  // Using a regular expression to check if the value contains only numbers
  // and is not an empty string.
  return /^[0-9]+(\.[0-9]+)?$/.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error("Only numbers are allowed"));
};

export const onlyPositiveNumbersValidator = (_, value) => {
  // Using a regular expression to check if the value contains only numbers
  // and is greater than zero.
  if (/^[0-9]+$/.test(value) && Number(value) > 0) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("Only positive numbers greater than zero are allowed")
  );
};

/**
 * Renders a tag for an application status with a tooltip.
 *
 * The function takes a status string and a color, and returns
 * a styled tag component from Ant Design. The tag displays an
 * abbreviation of the status (first letter of each word) and uses
 * the provided color. A tooltip with the full status text is shown
 * on hover.
 *
 * @param {string} status - The full text of the application status.
 * @param {string} color - The color to be used for the tag's background.
 * @returns {React.Component} A Tag component with a tooltip.
 */

export const renderApplicationStatusTag = (status, color) => {
  const abbreviatedStatus = status
    .split(" ") // Split the status into words
    .map((word) => word[0]) // Map each word to its first letter
    .join(""); // Join the first letters

  return (
    <Tooltip title={status} color={color}>
      <Tag color={color}>{abbreviatedStatus}</Tag>
    </Tooltip>
  );
};

/**
 * getUserPersonalStatusbyID - Extracts the label of a user status by matching the value to a given ID.
 * @param {Array} data - Array of user status objects.
 * @param {number|string} id - The ID to match the user status.
 * @returns {string|undefined} - The label of the matched user status or undefined if not found.
 */

export const getUserPersonalStatusbyID = (data, id) => {
  let extractedData = data?.filter((item) => {
    return item?.value === id;
  });

  return extractedData[0]?.label;
};

/**
 * getOrdinalNumber - Converts a number to its ordinal form (e.g., 1st, 2nd, 3rd).
 * @param {number} n - The number to convert.
 * @returns {string} - The ordinal form of the number.
 */

export function getOrdinalNumber(n) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * GetRole - Retrieves the role name from the first element in the data array.
 * @param {Array} data - Array containing user role objects.
 * @returns {string|undefined} - The role name of the first user or undefined if not found.
 */

export const GetRole = (data) => {
  return data[0]?.role?.name;
};

/**
 * GetUserOrganization - Retrieves the name of the first organization associated with the user from localStorage.
 * @returns {string} - The name of the user's first organization or an empty string if not found.
 */
export const GetUserOrganization = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let organization =
    user?.user?.userOrganizations.length > 0
      ? user?.user?.userOrganizations[0]?.organization?.name
      : "";
  return organization;
};

/**
 * formatNumber - Formats a number to a more readable string with appropriate suffixes (K, M, B, T).
 * @param {number} num - The number to format.
 * @param {number} [precision=2] - The number of decimal places.
 * @returns {string} - The formatted number with the appropriate suffix.
 */
export default function formatNumber(num, precision = 2) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  // Add an additional condition for precision less than 1000
  if (Math.abs(num) < 1000) {
    precision = 0;
  }

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}

/**
 * generateRandomNumber - Generates a random number string of a specified length.
 * @param {number} length - The length of the random number string.
 * @returns {string} - The generated random number string.
 */

export function generateRandomNumber(length) {
  let result = "";
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  for (let i = 0; i < length; i++) {
    const digit = array[i] % 10; // Generate a digit between 0 and 9
    result += digit.toString();
  }
  return result;
}
/**
 * ThaiPhoneNumberValidator - Validates a Thai phone number.
 * @param {Object} _ - Placeholder parameter for the rule object (not used).
 * @param {string} value - The phone number to validate.
 * @returns {Promise} - Resolves if the phone number is valid, rejects with an error message otherwise.
 */

export const ThaiPhoneNumberValidator = (_, value) => {
  if (!value) {
    // If the field is empty
    return Promise.reject(new Error("Phone number is required"));
  } else if (!/^(0)[0-9]{9}$/.test(value)) {
    // General check for 10 digits starting with 0
    return Promise.reject(new Error("Invalid phone number format"));
  } else if (!/^(0)([689])[0-9]{8}$/.test(value)) {
    // Check for mobile phone formats
    return Promise.reject(
      new Error(
        "Invalid Thai phone number format. Number must start with 06, 08, or 09"
      )
    );
  }
  // If the number passes all checks
  return Promise.resolve();
};
// field.parameterName field.parameterName===dropdownOptions[parameterName]

export const renderFieldDynamicaly = (
  field,
  dropdownOptions,
  parameterName
) => {
  switch (field.metaData) {
    case "Input":
      return <Input placeholder={field.parameterName} />;
    case "DropDown":
      return (
        <CustomSelect
          placeholder={`Select ${field.parameterName}`}
          options={dropdownOptions[parameterName]}
          className="custom-select"
        />
      );

    default:
      return null;
  }
};

export function groupParametersByType(parameters) {
  const grouped = {};
  parameters.forEach((param) => {
    const key = param.parameterName;
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push({
      label: param.parameterValue,
      value: param.parameterValueId,
      ...param,
    });
  });
  return grouped;
}

/**
 * Calculate the down payment amount.
 * @param {number} RRP - The Recommended Retail Price of the product.
 * @param {number} downPaymentPercentage - The percentage of RRP to be paid as a down payment.
 * @returns {number} The calculated down payment amount.
 */
export const calculateDownPayment = (RRP, downPaymentPercentage) =>
  (RRP * downPaymentPercentage) / 100;

/**
 * Calculate the processing fees.
 * @param {number} RRP - The Recommended Retail Price of the product.
 * @param {number} processingFeesPercentage - The percentage of RRP to be paid as processing fees.
 * @returns {number} The calculated processing fees amount.
 */
export const calculateProcessingFees = (RRP, processingFeesPercentage) =>
  (RRP * processingFeesPercentage) / 100;

/**
 * Calculate the financed amount.
 * @param {number} RRP - The Recommended Retail Price of the product.
 * @param {number} downPaymentAmount - The amount to be paid as a down payment.
 * @returns {number} The calculated financed amount.
 */
export const calculateFinancedAmount = (RRP, downPaymentAmount) =>
  RRP - downPaymentAmount;

/**
 * Calculate the financing fees.
 * @param {number} financedAmount - The amount to be financed.
 * @param {number} financingFeesPercentage - The percentage of financed amount to be paid as financing fees.
 * @param {number} maxTenure - The maximum tenure for financing.
 * @param {number} tenure - The selected tenure for financing.
 * @returns {number} The calculated financing fees amount.
 */
export const calculateFinancingFees = (
  financedAmount,
  financingFeesPercentage,
  maxTenure,
  tenure
) => {
  let financingFee =
    ((financingFeesPercentage / 12) *
      (tenure > 12 ? tenure / 4 : tenure) *
      financedAmount) /
    100;
  return financingFee;
};

/**
 * Calculate the insurance amount.
 * @param {number} RRP - The Recommended Retail Price of the product.
 * @param {number} insurancePercentage - The percentage of RRP to be paid as insurance.
 * @returns {number} The calculated insurance amount.
 */
export const calculateInsurance = (RRP, insurancePercentage) => {
  return (RRP * insurancePercentage) / 100;
};

/**
 * Calculate the first payment amount.
 * @param {number} downPayment - The down payment amount.
 * @param {number} processingFees - The processing fees amount.
 * @param {number} insurance - The insurance amount.
 * @param {number} DLC - Device Locking Control fee.
 * @returns {number} The calculated first payment amount.
 */
export const calculateFirstPayment = (
  downPayment,
  processingFees,
  insurance,
  DLC
) => downPayment + processingFees + insurance + DLC;

/**
 * Calculate the installment amount.
 * @param {number} financedAmount - The amount to be financed.
 * @param {number} financingFees - The financing fees amount.
 * @param {number} tenure - The selected tenure for financing.
 * @returns {number} The calculated installment amount.
 */
export const calculateInstallmentAmount = (
  financedAmount,
  financingFees,
  tenure
) => ((financedAmount + financingFees) / tenure).toFixed();

/**
 * Filter products based on selected variants.
 * @param {Array} products - The list of products to filter.
 * @param {Object} selectedVariants - The selected variants for filtering.
 * @returns {Array} The filtered list of products.
 */

export const filterProductsByVariants = (products, selectedVariants) => {
  if (!products) {
    return [];
  }

  return products.filter((product) => {
    if (!product.parameter) {
      return product?.financingPlans;
    }

    return Object?.keys(selectedVariants)?.every(
      (variantType) =>
        product?.parameter[variantType] === selectedVariants[variantType]
    );
  });
};

/**
 * Get financing plans from filtered products.
 * @param {Array} filteredProducts - The filtered list of products.
 * @returns {Array} The list of financing plans from the filtered products.
 */
export const getFinancingPlansFromProducts = (filteredProducts) => {
  return filteredProducts?.map((product) => product?.financingPlans);
};

/**
 * Find the closest number greater than or equal to the target from a predefined list.
 * @param {number} target - The target number.
 * @returns {number} The closest number greater than or equal to the target.
 */
export function ClosestNumber(target) {
  let numbers = [3, 6, 9, 12];

  // Initialize closest to null and smallestDifference to a large number
  let closest = null;
  let smallestDifference = Infinity;

  for (let i = 0; i < numbers.length; i++) {
    // Use < instead of <= for the loop
    let currentNumber = numbers[i];

    // Check if the current number is greater than or equal to the target
    if (currentNumber === target) {
      return target;
    } else if (currentNumber >= target) {
      let currentDifference = Math.abs(target - currentNumber);

      // Update closest and smallestDifference if the current difference is smaller
      if (currentDifference < smallestDifference) {
        closest = currentNumber;
        smallestDifference = currentDifference;
      }
    }
  }

  return closest;
}

/**
 * Find insurance products within a given price range.
 * @param {number} price - The price to check against.
 * @param {Array} products - The list of products to check.
 * @returns {Array} The list of products within the specified price range.
 */
export function findInsuranceInPriceRange(price, products) {
  return products?.filter(
    (product) => price >= product.minPrice && price <= product.maxPrice
  );
}

export function formatNumberWithCommas(number) {
  let formatedNumber = +number;
  if (formatedNumber === null) {
    return ""; // You can customize the error message as needed
  }
  if (typeof formatedNumber === "number") {
    return Number(formatedNumber.toFixed(0)).toLocaleString("en-US");
  } else {
    return "Error: Invalid number"; // You can customize the error message for invalid numbers
  }
  // return number.toLocaleString('en-US');
}

export const promoCodeValidator = (_, value) => {
  // Regular expression that matches strings that are only uppercase letters and numbers
  const regex = /^[A-Z0-9]+$/;

  if (!value) {
    return Promise.resolve(); // Ensure empty strings are handled according to the 'required' rule
  }

  if (!regex.test(value)) {
    return Promise.reject(
      new Error(
        "Promo code must only contain uppercase letters and numbers, without spaces."
      )
    );
  }

  return Promise.resolve();
};

/**
 * Validates a Pakistani phone number.
 *
 * This function checks if the provided phone number starts with '03' and contains only digits.
 * Additionally, it ensures that the phone number is exactly 11 digits long.
 *
 * @param {Object} _ - Placeholder parameter for the rule object (not used).
 * @param {string} value - The phone number to validate.
 * @returns {Promise} - Resolves if the phone number is valid, rejects with an error message otherwise.
 */
export const validatePakistaniPhoneNumber = (_, value) => {
  // Regular expression for Pakistani phone numbers in the "03" format
  const isValidPhoneNumber = /^03[0-9]+$/.test(value);

  // Check if the phone number matches the regex pattern
  if (!isValidPhoneNumber) {
    return Promise.reject(
      "Please enter a valid phone number starting with '03'."
    );
  }

  // Ensure the phone number is exactly 11 digits long
  if (value.length !== 11) {
    return Promise.reject("Phone number should be 11 digits");
  }

  // If all checks pass, resolve the promise
  return Promise.resolve();
};

export const DOCUMENT_STATUS_OPTIONS = [
  {
    status: 1,
    value: "Active",
  },
  {
    status: 0,
    value: "In Active",
  },
];

export const getStatusCode = (statusText) => {
  const { status } = DOCUMENT_STATUS_OPTIONS.find((statusObj) =>
    statusObj.value.includes(statusText)
  );
  return status;
};

export const getTransformedResByStatusCode = (contents, statusKey) => {
  const transformedRes = contents.map((content) => {
    return {
      ...content,
      [statusKey]: content[statusKey] === 0 ? "In Active" : "Active",
    };
  });

  return transformedRes;
};

export const generateInvoiceNumber = () => {
  const randomNum1 = window.crypto
    .getRandomValues(new Uint8Array(9)) // Generate 9 bytes for a 19-digit number
    .reduce((acc, byte) => acc + byte.toString().padStart(3, "0"), "")
    .slice(0, 19); // Ensure the length is exactly 19 digits

  const randomNum2 = window.crypto
    .getRandomValues(new Uint8Array(9)) // Generate 9 bytes for a 19-digit number
    .reduce((acc, byte) => acc + byte.toString().padStart(3, "0"), "")
    .slice(0, 19); // Ensure the length is exactly 19 digits

  const fiscalYear = new Date().getFullYear() + 1;
  return `${randomNum1}/FY${fiscalYear.toString().slice(-2)}/${randomNum2}`;
};
